.collage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-img {
   
  width: 100%;
  height: auto;
  max-height: 200px;
  /* object-fit: cover; */
}

.thumbnails {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 5px;
  max-width: 500px;
  overflow-x: auto;
}

/* .thumbnail {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
} */
.thumbnail {
  width: 60px;
  height: 60px;
 
  /* object-fit: cover; */
  margin-right: 10px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.thumbnail-icon-delete{
  cursor: pointer;
   
}
.thumbnail:hover {
  opacity: 0.7;
}

.thumbnail:hover {
  border-color: #ff6600;
}

.thumbnail:last-child {
  margin-right: 0;
}

button {
  margin-top: 10px;
}

.nueva .thumbnail {
  border: 1px solid rgb(23, 158, 30);
  filter: saturate(150%);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  /* animation: fadeIn 1s ease-in-out; */
  animation: pulse 2s infinite;
  /* animation: slide 5s infinite; */
  cursor: pointer;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
 

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}